<template>
  <v-container class="full-height">
    <div class="d-flex justify-center align-center full-height">
      <v-card min-width="50%">
        <v-card-title>
          <span class="mainTitle" style="font-size: 20px"> Reset Password </span>
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="newPassword" label="New Password" required type="password" />
        </v-card-text>
        <v-card-text>
          <v-text-field
            v-model="confirmPassword"
            label="Confirm new Password"
            type="password"
            :error-messages="error"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="submit">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import api from '@/api';
import { resetPasswordByToken } from '@/api/auth/sso';

export default {
  data() {
    return {
      newPassword: null,
      confirmPassword: null,
      error: null,
    };
  },
  props: {
    token: {},
  },
  methods: {
    async submit() {
      this.error = null;
      if (this.newPassword !== this.confirmPassword) {
        this.error = 'The passwords entered are not the same';
        return;
      }
      try {
        await resetPasswordByToken(api, this.newPassword, { token: this.token });
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Password changed',
          type: 'success',
          color: 'success',
        });
        this.$router.replace({ name: 'login' }).catch(() => {});
      } catch (err) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'The token is expired. Please reset password with a new link',
          color: 'danger',
        });
      }
    },
  },
};
</script>
